import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  // RedditShareButton,
  // RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  LineShareButton,
  LineIcon,
  // GooglePlusShareButton,
  // GooglePlusIcon,
  // LinkedinShareButton,
  // LinkedinIcon,
  // PinterestShareButton,
  // PinterestIcon,
  // TumblrShareButton,
  // TumblrIcon,
  // ViberShareButton,
  // ViberIcon,
  // VKShareButton,
  // VKIcon,
  // OKShareButton,
  // OKIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

const BottomBar = () => {
  const url = window.location.href;

  return (
    <div className="bottombar">
      <div className="bottombar__wrapper">
        <div className="bottombar__right">
          <FacebookShareButton className="bottombar__btn" url={url}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <LineShareButton className="bottombar__btn" url={url}>
            <LineIcon size={32} round />
          </LineShareButton>
          <WhatsappShareButton className="bottombar__btn" url={url}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <TwitterShareButton className="bottombar__btn" url={url}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <TelegramShareButton className="bottombar__btn" url={url}>
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          <EmailShareButton className="bottombar__btn" url={url}>
            <EmailIcon className="bottombar__icon" size={32} round />
          </EmailShareButton>
          <CopyToClipboard text={url}>
            <div>
              <button className="bottombar__btn" id="shareCopyButton">
                <img
                  className="bottombar__icon"
                  src={`${process.env.PUBLIC_URL}/img/copy.png`}
                  alt={"general.copy_to_clipboard"}
                />
              </button>
            </div>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

export default BottomBar;
