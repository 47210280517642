import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from "react-hot-loader";
import "bootstrap/dist/css/bootstrap.css";
import "../../scss/app.scss";
import { store, persistor } from "./store";
import Router from "./Router";
import ScrollToTop from "./ScrollToTop";
import { config as i18nextConfig } from "../../translations";
import { LocalizationInitializer } from "../../shared/components/Localization";

i18next.init(i18nextConfig);

/* class App extends Component {
  static propTypes = {
    i18next: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired
  };

  render() {
    // const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename="/">
            <I18nextProvider i18n={i18next}>
              <LocalizationInitializer />
              <ScrollToTop>
                <div>
                  <Router />
                </div>
              </ScrollToTop>
            </I18nextProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}　*/

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename="/">
        <I18nextProvider i18n={i18next}>
          <LocalizationInitializer />
          <ScrollToTop>
            {/* {!loaded &&
                <div className={`load${loading ? '' : ' loaded'}`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  </div>
                </div>
              } */}
            <div>
              <Router />
            </div>
          </ScrollToTop>
        </I18nextProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

App.propTypes = {
  i18next: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
};

export default hot(module)(App);
