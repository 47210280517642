import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

/* class TopbarProfile extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={`${process.env.PUBLIC_URL}/img/menu/aget_menu.png`} alt="Menu" />
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title={t('footer.schools')} image="menu/aget_schools.png" path="/schools" onClick={this.toggle} />
            <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/menu/aget_menu_line.png`} alt="Menu-line" />
            <TopbarMenuLink title={t('footer.about_us')} image="menu/aget_about_us.png" path="/about-us" onClick={this.toggle} />
            <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/menu/aget_menu_line.png`} alt="Menu-line" />
            <TopbarMenuLink title={t('footer.store')} image="menu/aget_store.png" external path="https://store.joyajoy.com" onClick={this.toggle} />
            <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/menu/aget_menu_line.png`} alt="Menu-line" />
            <TopbarMenuLink title={t('footer.partners')} image="menu/aget_partners.png" path="/partners" onClick={this.toggle} />
            <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/menu/aget_menu_line.png`} alt="Menu-line" />
            <TopbarMenuLink title={t('footer.contact_us')} image="menu/aget_contact.png" path="/contact-us" onClick={this.toggle} />
          </div>
        </Collapse>
      </div>
    );
  }
} */

const isMaintenance = process.env.REACT_APP_MAINTENANCE === 'true';

const TopbarProfile = ({
  t,
}) => {
  const [collapse, setCollapse] = useState(false);

  return !isMaintenance && (
    <div className="topbar__profile">
      <button className="topbar__avatar" onClick={() => setCollapse(!collapse)}>
        <img className="topbar__avatar-img" src={`${process.env.PUBLIC_URL}/img/menu/aget_menu.png`} alt="Menu" />
      </button>
      {collapse && <button className="topbar__back" onClick={() => setCollapse(!collapse)} />}
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          {!isMaintenance && <TopbarMenuLink title={t('footer.schools')} image="menu/aget_schools.png" path="/schools" onClick={() => setCollapse(!collapse)} />}
          {!isMaintenance && <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/menu/aget_menu_line.png`} alt="Menu-line" />}
          {!isMaintenance && <TopbarMenuLink title={t('footer.about_us')} image="menu/aget_about_us.png" path="/about-us" onClick={() => setCollapse(!collapse)} />}
          {!isMaintenance && <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/menu/aget_menu_line.png`} alt="Menu-line" />}
          {!isMaintenance && <TopbarMenuLink title={t('footer.store')} image="menu/aget_store.png" external path="https://store.joyajoy.com" onClick={() => setCollapse(!collapse)} />}
          {!isMaintenance && <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/menu/aget_menu_line.png`} alt="Menu-line" />}
          {!isMaintenance && <TopbarMenuLink title={t('footer.partners')} image="menu/aget_partners.png" path="/partners" onClick={() => setCollapse(!collapse)} />}
          {!isMaintenance && <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/menu/aget_menu_line.png`} alt="Menu-line" />}
          {!isMaintenance && <TopbarMenuLink title={t('footer.contact_us')} image="menu/aget_contact.png" path="/contact-us" onClick={() => setCollapse(!collapse)} />}
        </div>
      </Collapse>
    </div>
  );
};

TopbarProfile.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(TopbarProfile);
