import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const TopbarMenuLinks = ({
  title,
  /* icon, */ image,
  path,
  onClick,
  external,
}) =>
  external ? (
    <a className="topbar__link" href={path} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      <span className="topbar__link-icon">
        <img
          className="topbar__avatar-img"
          src={`${process.env.PUBLIC_URL}/img/${image}`}
          alt=""
        />
      </span>
      {/* <span className="topbar__link-icon"><img className={`lnr lnr-${icon}`} src={`${process.env.PUBLIC_URL}/img/${image}`} alt="" /></span> */}
      <p className="topbar__link-title">{title}</p>
    </a>
  ) : (
    <Link className="topbar__link" to={path} onClick={onClick}>
      <span className="topbar__link-icon">
        <img
          className="topbar__avatar-img"
          src={`${process.env.PUBLIC_URL}/img/${image}`}
          alt=""
        />
      </span>
      {/* <span className="topbar__link-icon"><img className={`lnr lnr-${icon}`} src={`${process.env.PUBLIC_URL}/img/${image}`} alt="" /></span> */}
      <p className="topbar__link-title">{title}</p>
    </Link>
  );

TopbarMenuLinks.propTypes = {
  title: PropTypes.string.isRequired,
  // icon: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  external: PropTypes.bool,
};

TopbarMenuLinks.defaultProps = {
  onClick: undefined,
  external: false,
};

export default TopbarMenuLinks;
